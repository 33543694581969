import logo from '../images/logo.svg';

function Nav({ navState, setNavState, navChanged, setNavChanged }: {
    navState: boolean, setNavState: Function, navChanged: boolean, setNavChanged: Function
}) {

    return (
        <>
            <nav>
                <div className='nav__container'>
                    <img className='nav__logo' src={logo} alt='Logo' />
                    <div onClick={() => {
                        setNavState(!navState);
                        if (!navChanged) { setNavChanged(true); }
                    }} className={'menu__icon' + (navState ? ' open' : '')}>
                        <span />
                        <span />
                        <span />
                    </div>
                    <ul className='menu--desktop'>
                        <li className='menu__item menu__item--desktop'>Home</li>
                        <li className='menu__item menu__item--desktop'>About</li>
                        <li className='menu__item menu__item--desktop'>Services</li>
                        <li className='menu__item menu__item--desktop'>Contact</li>
                    </ul>
                </div>
            </nav>
            <ul className={'menu--mobile' + (navState ? ' menu__animation--open' : navChanged ? ' menu__animation--close' : '')}>
                <li style={{ '--order': 1 } as any} className={'menu__item menu__item--mobile' + (navState ? ' menu__animation--fadein' : ' menu__animation--fadeout')}>Home</li>
                <li style={{ '--order': 2 } as any} className={'menu__item menu__item--mobile' + (navState ? ' menu__animation--fadein' : ' menu__animation--fadeout')}>About</li>
                <li style={{ '--order': 3 } as any} className={'menu__item menu__item--mobile' + (navState ? ' menu__animation--fadein' : ' menu__animation--fadeout')}>Services</li>
                <li style={{ '--order': 4 } as any} className={'menu__item menu__item--mobile' + (navState ? ' menu__animation--fadein' : ' menu__animation--fadeout')}>Contact</li>
            </ul>
        </>
    )
}

export default Nav;