import heroVector from '../images/hero-vector.svg';
//import { useSelector, useDispatch } from 'react-redux';
//import { decrement, increment } from './counter';
//import { RootState } from '../store';

function Hero() {
    // const count = useSelector((state: RootState) => state.counter.value);
    // const dispatch = useDispatch();
    return (
        <div className='hero__container'>
            <img className='hero__vector' alt='Hero Vector' src={heroVector} />
            <div className='hero__center__elements'>
                <h1 className='hero__text--main'>Providing Innovative Web <br />Development Services</h1>
                <h2 className='hero__text--sub'>Envodevel is a Web Development Agency, which prides itself on building high-quality Web Applications</h2>
                <button className='hero__cta'>Contact Us!</button>
            </div>
        </div>
    )
}

export default Hero;