import AboutOverview from '../images/about-overview.svg';
import AboutMVP from '../images/about-mvp.svg';
import AboutEnhancement from '../images/about-enhancement.svg';


function About() {
    return (
        <div className='about__container'>
            <h2 className='about--main'>Web Development Process</h2>
            <div className='about__section about__overview'>
                <div className='about__overview--text'>
                    <h3 className='about__overview--sub'>MVP Development and Product Enhancement</h3>
                    <p className='about__paragraph'>
                        We offer two main services, which are: <b>MVP Development</b> and <b>Product Enhancement</b>.
                        We always recommend using the MVP Development approach for building a web appliction from scratch, as it comes with many advantages that could influence the future of your business. The other offering, Product Enhancment improves the quality and features of a pre-existant website that you would like to improve.
                    </p>
                </div>
                <img className='about__overview--image' alt='About Overview Illustration' src={AboutOverview} />
            </div>
            <div className='about__section about__mvp'>
                <img className='about__mvp--image' alt='About MVP Illustration' src={AboutMVP} />
                <div className='about__mvp--text'>
                    <h3 className='about__mvp--sub'>MVP Web Development</h3>
                    <p className='about__paragraph'>
                        MVP Web Development allows for more affordable, and quicker development of your Web Application. An MVP also empowers you to test the market, and business opportunity before the complete website is built.
                        <br /><br />
                        An MVP is also very helpful for small to medium size companies, who don't want to invest lots of money into the development of a complete Web Application.
                        <br /><br />
                        An MVP is a fully functioning Web Application, nevertheless, it misses finishing touches, and often complex functionality. However, it is the perfect choice when looking to release a beta or test version of your app.
                    </p>
                </div>
            </div>
            <div className='about__section about__enhancement'>
                <div className='about__enhancement--text'>
                    <h3 className='about__enhancement--sub'>Product Enhancement</h3>
                    <p className='about__paragraph'>
                        Product Enhancement allows for use to take your pre-existing code, and improve aspects such as (but not limited to) the UI design, speed and performance, future refactor ability, additional tools to manage the site.
                        <br /><br />
                        Many old (and some new) websites are built using obsolete technology stacks, and are outdated. Using newer updated versions of stacks, we can ensure you will have an up-to-date website using current technologies.
                        <br /><br />
                        Getting started is easy! Simple send us a message, and we can start to talk about your aspirations for the enhancement through what you don't like about your current site, and what you would like to see in an updated site.
                    </p>
                </div>
                <img className='about__enhancement--image' alt='About MVP Illustration' src={AboutEnhancement} />
            </div>
            <div className='cta__section'>
                <h3 className='cta__sub'>Interested?</h3>
                <p className='cta__text'>Learn more about the services we offer!</p>
                <button className='cta__button'>Learn More</button>
            </div>
        </div>
    )
}

export default About;