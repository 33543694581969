import React, { useState, useEffect } from 'react';

import './css/home/main.css';
import './css/home/nav.css';
import './css/home/hero.css';
import './css/home/about.css';
import './css/home/contact.css';

import Nav from './components/nav';
import Hero from './components/hero';
import About from './components/about';
import Contact from './components/contact';


function Home() {
  const Webfont = require('webfontloader');

  useEffect(() => {
    Webfont.load({
      google: {
        families: ['Lato:300,400,700',
          'Roboto Mono:400,700']
      }
    });
  });

  const [navState, setNavState] = useState(false);
  const [navChanged, setNavChanged] = useState(false);

  return (
    <div className='container'>
      <Nav navState={navState} setNavState={setNavState} navChanged={navChanged} setNavChanged={setNavChanged} />
      <main className={'main' + (navState ? ' blur' : navChanged ? ' noblur' : '')} >
        <Hero />
        <About />
        <Contact />
      </main>
    </div >
  );
}

export default Home;
