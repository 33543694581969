import { waitFor } from "@testing-library/react";
import React, { useState } from "react";

function Contact() {

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [showSent, setShowSent] = useState<boolean>(false);
    const [messageError, setMessageError] = useState<boolean>(false);

    function sendMessage() {
        if (!email.includes('@') || !email.split('@')[1].includes('.') || email[0] === '.' || email[email.length - 1] === '.') {
            alert('Invalid Email');
            return;
        }

        fetch('https://envodevel.com/api/email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "name": name, "email": email, "subject": subject, "message": message })
        })
            .then(response => response.text())
            .then(response => {
                if (response == 'ok') {
                    setShowSent(true);
                    setMessageError(false);
                }
                else {
                    setMessageError(true);
                    setShowSent(false);
                }
            }).catch(error => {
                console.log(error);
            });
    }


    return (
        <div className='contact__container'>
            <h3 className='contact__text--title'>CONTACT US</h3>
            <p className='contact__text--sub'>If you would like to get in contact with us, simply send us a message here or email us!</p>
            <div className='contact__form'>
                <input value={name} onChange={e => setName(e.target.value)} placeholder='Name' id='contact__name' className='contact__form--field' type='text' />
                <input value={email} onChange={e => setEmail(e.target.value)} placeholder='Email' id='contact__email' className='contact__form--field' type='email' />
                <input value={subject} onChange={e => setSubject(e.target.value)} placeholder='Subject' id='contact__subject' className='contact__form--field' type='text' />
                <textarea value={message} onChange={e => setMessage(e.target.value)} placeholder='Message' id='contact__message' className='contact__form--field' rows={5} maxLength={1000}></textarea>
                <button onClick={() => sendMessage()} className='contact__send'>Send</button>
            </div>
        </div >
    )
}

export default Contact;